import React from "react";
import ReactDOM from "react-dom";
import "./style.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import Routes from "./routes";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

ReactDOM.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <Routes />
    </DndProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
