import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";

const Auditorium= lazy(() => import("./pages/auditorium"))
const Lobby= lazy(() => import("./pages/lobby"))
const Login= lazy(() => import("./pages/login"))
const Map= lazy(() => import("./pages/map"))
const StandEpiCx= lazy(() => import("./pages/standEpiCx"))
const StandTheLab= lazy(() => import("./pages/standTheLab"))
const Admin= lazy(() => import("./pages/admin/admin"))
const Cover= lazy(() => import("./pages/cover"))
const Questions= lazy(() => import("./pages/questions/questions"))
const TopTen= lazy(() => import("./pages/topTen/topTen"))
const P404= lazy(() => import("./pages/page404/page404"))
const PhotoBooth = lazy(() => import("./pages/photobooth/photobooth"))

const Routes = () => {
  return (
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path={["/", "/login"]} component={Login} />
            <Route exact path="/room" component={Auditorium} />
            <Route exact path="/lobby-stands" component={Lobby} />
            <Route exact path="/lobby" component={Map} />
            <Route exact path="/photobooth" component={PhotoBooth} />
            <Route exact path="/stand-epi-cx" component={StandEpiCx} />
            <Route exact path="/stand-the-lab" component={StandTheLab} />
            <Route exact path="/config-admin" component={Admin} />
            <Route exact path="/config-admin-questions" component={Questions} />
            <Route exact path="/config-admin-score" component={TopTen} />
            <Route component={P404}/>
          </Switch>
        </Suspense>
      </BrowserRouter>
  );
}

export default Routes

